<template>
  <div class="content">
    <img class="bg" alt="Vue logo" src="~@/assets/bg.png">
    <div class="footer">
      <div>
        <img class="logo" alt="Vue logo" src="~@/assets/footer-logo.png">
        <p>上海上语通信科技有限公司</p>
        <a class="link" target="view_window" href="https://beian.miit.gov.cn/">苏ICP备2023021211号</a>
      </div>
      <div>
        <p class="title">技术服务热线</p>
        <p class="big">0515-67630246</p>
        <p>服务时间：9:00-18:00（工作日）</p>
      </div>
      <div>
        <p class="title">邮箱</p>
        <p class="big">huangpp@cangfengsz.com</p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'homePage'
}
</script>

<style scoped>
body,html,p{
  margin: 0;
  padding: 0;
}
.content {
  position: relative;
}
.bg{
  width: 100%;
  display: block;
}
.link{
  color: #f0f0f0;
  font-size: 12px;
}
.footer{
  height: 12%;
  width: 78%;
  color: #f0f0f0;
  position: absolute;
  bottom: 0;
  left: 11%;
  z-index: 2;
  font-size: 12px;
  display: flex;
  align-items: center;
  line-height: 40px;
}
.footer>div{
  width: 33.3%;
  height: 100px;
}
.footer .logo{
  height: 23px;
}
.title{
  font-size: 18px;
  /* line-height: 22px; */
}
.big{
  font-size: 22px;
  /* line-height: 22px; */
}
</style>
